/* You can add global styles to this file, and also import other style files */

$gutter: 0.3rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

.p-sidebar-header-text {
  color: white;
  background-color: #004a87;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: x-large;
}

.invalid-feedback {
  color: var(--red-500);
}

.float-right {
  float: right;
}
.w-100 {
  width: 100%;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #eff6ff;
  color: #1d4ed8;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #eff6ff;
  color: #1d4ed8;
}
.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid #eff6ff;
  outline-offset: 0.15rem;
}

.p-calendar-sm {
  font-size: 0.875rem !important;
  padding-top: 0.4375rem !important;
  padding-right: 0.65625rem !important;
  padding-bottom: 0.4375rem !important;
  padding-left: 0.65625rem !important;
}

.p-disabled {
  pointer-events: none !important;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: white;
  //box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--surface-200);
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.p-dropdown .p-dropdown-label {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-multiselect .p-multiselect-label {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-tabview .p-tabview-panels {
  padding: 0.5rem;
}
.p-datatable .p-datatable-thead > tr > th {
  border-top: 0;
}

.p-splitbutton .p-splitbutton-defaultbutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-selectbutton .p-button {
  border: 0;
  margin-right: 3px;
  background: #fff;
  color: var(--text-color);
}
.p-selectbutton .p-button.p-highlight {
  background: #eff6ff;
  color: #1d4ed8;
}
.layout-topbar {
  background-color: #004a87;
}

.card {

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

}
.button-18 {
  background-color: #004a87;
  margin-right: 0.5rem;
}
.button-18:hover {
  background-color: #ffffff;
  color: #004a87;
  margin-right: 0.5rem;
}
.button_login {
  background-color: #004a87;

}
.button_login:hover {
  background-color: #36a8e0;
  border-color: #36a8e0;
}
.button_login02 {
  background-color: #ffffff;
  color: #004a87;
}
.button_login02:hover {
  background-color: #ffffff;
  color: #36a8e0;
  border-color: #36a8e0;
}


.p-danger-text {
  border-color: #dc3545;
  color: #dc3545;
  margin-left: 0.5rem;
}
.p-danger-text:hover {
  background-color: #dc3545;
  color: #ffffff;
}

.p-edit-text {
  border-color: #004a87;
  color: #004a87;
  background-color: transparent;
}
.p-edit-text:hover {
  background-color: #004a87 !important;
  color: white !important;
}





.txt_login {
  color: #004a87;
}
.txt_recupera_password {
  color: #004a87;
}
.txt_recupera_password:hover {
  color: #36a8e0;
}

.img-logo-login {
  padding-bottom: 1rem;
}

/*card dashboard*/
.card-header01 {
  position: relative;
  padding: 1rem 1rem;
  border-bottom: none;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 2;
  margin-left: -28px;
  margin-right: -28px;
  margin-top: -28px;
  border-radius: 12px 12px 0 0;
  background-image: url("/assets/layout/images/themes/img-01.jpg");
  height: 157px;
  background-size: cover;
}

.card-header02 {
  position: relative;
  padding: 1rem 1rem;
  border-bottom: none;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 2;
  margin-left: -28px;
  margin-right: -28px;
  margin-top: -28px;
  border-radius: 12px 12px 0 0;
  background-image: url("/assets/layout/images/themes/img-02.jpg");
  height: 157px;
  background-size: cover;
}

.card-header03 {
  position: relative;
  padding: 1rem 1rem;
  border-bottom: none;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 2;
  margin-left: -28px;
  margin-right: -28px;
  margin-top: -28px;
  border-radius: 12px 12px 0 0;
  background-image: url("/assets/layout/images/themes/img-03.jpg");
  height: 157px;
  background-size: cover;
}

.card-header04 {
  position: relative;
  padding: 1rem 1rem;
  border-bottom: none;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 2;
  margin-left: -28px;
  margin-right: -28px;
  margin-top: -28px;
  border-radius: 12px 12px 0 0;
  background-image: url("/assets/layout/images/themes/img-04.jpg");
  height: 157px;
  background-size: cover;
}


.icon {
  padding-bottom: 2rem;
  height: 90px;
}

.title-paragraph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.separator {
  color: white !important;
  border-top: solid white;
  border-width: 1px 0 0 0;
}

/*card template page*/
.template-header {
  color: #004a87;
}

.lista-header {
  color: #004a87;
}
/*card step page*/
.step-header {
  color: #004a87;
}

.titolo-card {
  text-transform:uppercase;
  font-weight:700;
  text-align: center;
  color:white!important;
  font-size:26px!important;
}

.numero-card {
  font-weight:700;
  text-align: center;
  color:white!important;
  font-size:75px!important;
}


/*titoli*/
.page-header {
  margin-bottom: 0.3rem;
}

.page-heading {
  font-weight: 700;
  color: #004a87;
  text-align: center;
  padding-top: 3.5rem;
}

.text-header {
  max-width: 1200px;
  margin: 1.5rem 0 1rem 0;
  text-align: center;
  font-size: 15px;
}



/*footer*/
.layout-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  align-self: end;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
  color: #617dbf;
  text-align: center;
  z-index: 1000;
}

.py-3 {
  background-color: #004a87;
  align-content: center;
}

.p-multiselect-panel {
  position: fixed;
}
// Calendar date picker dimensioni in base al contenuto
.custom-calendar-panel {
  z-index: 1000;
}

.p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
  padding: 3rem;
}

@media screen and (max-height: 660px){
  .custom-calendar-panel {
    transform: translateY(10%);
    transform: scaleY(0.8);
    z-index: 1000;
  }
}

@media screen and (max-height: 550px) {
  .custom-calendar-panel {
    position: absolute;
    top: calc(-270% + 1rem);
    left: -8rem;
    transform: scale(0.35);
    z-index: 1000;
  }
}