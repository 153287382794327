.card {
  background: var(--surface-card);
  border: 3px solid ;
  border-color:white;
  padding: 2rem;
  margin-bottom: 0.6rem;
  box-shadow: var(--card-shadow);
  border-radius: $borderRadius;
  

  &:last-child {
    margin-bottom: 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 100px;
  }
}





